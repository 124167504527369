<template>
  <v-app id="inspire">
    <overall-header/>


    <v-main class=" lighten-3">
      <v-container>
        <v-row
            align="start"
            justify="space-around"
            style="padding: 5vh"
        >
          <h1>{{ project_title }}</h1>
        </v-row>

        <v-row
            justify="space-between"
        >
          <v-col
              cols="12"
              md="12"
          >
            <v-form ref="form">
              <v-text-field
                  v-model="project_title"
                  label="Название объекта"
                  required
                  @input="$v.project_title.$touch()"
                  @blur="$v.project_title.$touch()"
                  disabled
              ></v-text-field>

              <v-text-field
                  v-model="project_address"
                  label="Адрес объекта"
                  required
                  @input="$v.project_address.$touch()"
                  @blur="$v.project_address.$touch()"
                  disabled
              ></v-text-field>

              <v-text-field
                  v-model="name_developer"
                  label="Застройщик"
                  required
                  @input="$v.name_developer.$touch()"
                  @blur="$v.name_developer.$touch()"
                  disabled
              ></v-text-field>

              <v-text-field
                  v-model="name_technical_customer"
                  label="Технический заказчик"
                  required
                  @input="$v.name_technical_customer.$touch()"
                  @blur="$v.name_technical_customer.$touch()"
                  disabled
              ></v-text-field>

              <v-text-field
                  v-model="name_project_organization"
                  label="Проектные организации"
                  required
                  @input="$v.name_project_organization.$touch()"
                  @blur="$v.name_project_organization.$touch()"
                  disabled
              ></v-text-field>

              <v-row>
                <v-col cols="12">
                  <v-row justify="space-between">
                    <v-col
                        cols="12"
                        md="12"
                    >
                      <v-text-field
                          v-model="search"
                          label="Поиск по секциям"
                          flat
                          hide-details
                          clearable
                          clear-icon="mdi-close-circle-outline"
                      ></v-text-field>

                      <v-data-table
                          :items="filtered_section_items"
                          :headers="headers"
                          :search="search"
                          item-key="id"
                          sort-by="section"
                          class="elevation-1"
                      >
                        <template v-slot:item.file="{ item }">
                          <v-file-input
                              chips
                              show-size
                              counter
                              label="Прикрепите файл"
                              v-on:change="uploadFile(item, $event)"
                          ></v-file-input>
                        </template>
                        <template v-slot:item.workers="{ item }">
                          <v-select
                              :items="workers"
                              v-on:change="selectedWorkers(item, $event)"
                              :value="getDefaultWorkers(item)"
                              :menu-props="{ maxHeight: '400' }"
                              label="Выбрать исполнителей"
                              multiple
                              full-width
                              persistent-hint
                              item-text="title"
                              item-value="id"
                          ></v-select>
                        </template>
                        <template v-slot:item.download="{ item }">
                          <v-btn
                              class="ma-2"
                              color="info"
                              @click="downloadFile(item)"
                          >
                            Скачать файл
                            <template v-slot:loader>
                            <span class="custom-loader">
                              <v-icon light>mdi-cached</v-icon>
                            </span>
                            </template>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

            </v-form>
          </v-col>

        </v-row>
        <v-row justify="space-between">
          <v-col
              cols="6"
              md="6"
          >

          </v-col>
          <v-col
              cols="6"
              md="6"
          >

            <v-dialog
                v-model="new_worker_dialog"
                persistent
                max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    tile
                    color="primary"
                    @click="createNewWorker"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Создать нового исполнителя
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Новый исполнитель</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            label="ФИО"
                            required
                            v-model="new_worker_fio"
                        ></v-text-field>

                        <v-text-field
                            label="Должность"
                            hint="Введите должность исполнителя"
                            v-model="new_worker_position"
                        ></v-text-field>

                        <v-file-input
                            chips
                            show-size
                            counter
                            label="Прикрепите факсимиле"
                            v-model="new_worker_sign"
                        ></v-file-input>
                        <v-img :src="url"/>
                      </v-col>


                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="new_worker_dialog = false"
                  >
                    Закрыть
                  </v-btn>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="handleWorkerCreation"
                  >
                    Сохранить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </v-col>

        </v-row>

      </v-container>
    </v-main>


  </v-app>
</template>

<script>
import OverallHeader from "@/components/OverallHeader";
import {
  // TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css';
import {imageToBase64} from "../../helpers/imageToBase64";
import Axios from "@/helpers/axiosConfig";

export default {
  name: "DetailIulProject",
  components: {OverallHeader},
  mixins: [validationMixin],

  validations: {
    project_title: {required},
    project_code: {required},
    project_address: {required},
    name_developer: {required},
    name_technical_customer: {required},
    name_project_organization: {required},
  },

  data: () => ({
    project_deadline: new Date(Date.now()),
    project_deadline_menu: false,

    project_answer_deadline: new Date(Date.now()),
    project_answer_deadline_menu: false,
    project_description: "",
    project_status: '',
    project_title: "",
    project_code: "",
    name_developer: "",
    name_technical_customer: "",
    name_project_organization: "",
    project_address: "",
    default_workers: {},
    selected_workers: {},
    project_sections: [],
    current_project_files: [],
    project_files: [],
    uploaded_files: {},
    filtered_section_items: [],
    editor_extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    search: null,
    new_worker_dialog: false,
    new_worker_sign: null,
    new_worker_sign_base64: null,
    new_worker_position: "",
    new_worker_fio: "",
    headers: [
      {
        text: 'Название',
        value: 'title',
      },
      {
        text: 'Загрузить файл',
        value: 'file',
      },
      {
        text: 'Исполнители',
        value: 'workers',
      },
      {
        text: 'Скачать ИУЛ',
        value: 'download',
      },
    ]
  }),

  computed: {
    ...mapGetters('iul_workers', {
      workers: 'list'
    }),
    ...mapGetters('iul_sectiontypes', {
      section_items: 'list'
    }),
    filter() {
      return (item, search, textKey) => item[textKey].indexOf(search) > -1
    },
    url() {
      if (!this.new_worker_sign) return;
      return URL.createObjectURL(this.new_worker_sign);
    },
  },

  methods: {
    ...mapActions('iul_projects', {
      fetchProject: 'fetchSingle',
      updateProject: 'replace',
      createIulProject: 'create',
    }),
    ...mapActions('iul_workers', {
      fetchWorkers: 'fetchList',
      createWorker: 'create',
    }),
    ...mapActions('iul_sectiontypes', {
      fetchSectionTypes: 'fetchList'
    }),

    loadDataForProject: function () {
      if (this.$route.params.id) {
        let that = this;
        this.fetchProject({id: this.$route.params.id}).then(function (response) {
          that.project_title = response.data.title;
          that.project_address = response.data.address;
          that.name_developer = response.data.name_developer;
          that.name_technical_customer = response.data.name_technical_customer;
          that.name_project_organization = response.data.name_project_organization;
          that.project_sections = Object.keys(response.data.default_workers_and_sections);
          that.default_workers = response.data.default_workers_and_sections;
        });
      }
    },

    filterSections() {
      this.project_sections.map((section) => {
        let filtered = this.section_items.filter(function (x) {
          return x.id.toString() === section
        });
        this.filtered_section_items.push(filtered[0])
      });
    },

    createNewWorker() {
      this.new_worker_dialog = true;
    },

    selectedWorkers(item, value) {
      this.selected_workers[item.id] = value;
      console.log("selectedWorkers", this.selected_workers);
    },

    uploadFile(item, value) {
      if (!value) return;

      var fd = new FormData();
      fd.append('input_file', value);
      fd.append('section_type', item.id);
      fd.append('project', this.$route.params.id);
      if (this.selected_workers[item.id] !== undefined) {
        fd.append('selected_workers', this.selected_workers[item.id]);
        console.log('uploadFile selected_workers', this.selected_workers)

      }

      var that = this;

      Axios({
        method: 'post',
        url: '/api_v3/iul/file/upload/',
        data: fd,
        headers: {
          "content-type": "multipart/form-data"
        }
      }).then(function (response) {
        console.log(response.data);
        that.uploaded_files[item.id] = response.data[0];
        if(response.data[0].workers.length > 0) {
          that.selected_workers[item.id] = response.data[0].workers;
          that.default_workers[item.id] = response.data[0].workers;
        }
        that.$forceUpdate();
      }).catch(function (error) {
        console.log(error)
      });

    },

    getDefaultWorkers(item) {
      this.selected_workers[item.id] = this.default_workers[item.id];
      return this.default_workers[item.id];
    },

    downloadFile(item) {
      Axios({
        method: 'post',
        url: '/api_v3/iul_file/'+this.uploaded_files[item.id].id+'/download_iul/',
        data: {
          workers: this.selected_workers[item.id]
        },
        responseType: 'blob', // important
      }).then(function (response) {
        let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }),
            downloadUrl = window.URL.createObjectURL(blob),
            filename = "",
            disposition = response.headers["content-disposition"];

        if (disposition && disposition.indexOf("attachment") !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
              matches = filenameRegex.exec(disposition);

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }

        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      }).catch(function (error) {
        console.log(error)
      });
    },

    thereIsFileToDownload(item) {
      console.log('thereIsFileToDownload', this.uploaded_files[item.id])
      return this.uploaded_files[item.id] !== undefined;
    },

    handleWorkerCreation() {
      if (!this.new_worker_sign) return;
      var that = this;
      imageToBase64(this.new_worker_sign).then((base64) => {
        this.createWorker(
            {
              data: {
                "fio": this.new_worker_fio,
                "sign": base64,
                "position": this.new_worker_position,
              },

            },
        ).then(() => {
          that.fetchWorkers();
          that.new_worker_fio = "";
          that.new_worker_position = "";
          that.new_worker_sign = null;
          that.new_worker_sign_base64 = null;
          that.new_worker_dialog = false;
        });
      });
    },
  },

  created() {
    this.fetchSectionTypes();
    this.fetchWorkers();
    this.loadDataForProject();
  },

  watch: {
    "section_items": "filterSections",
    "project_sections": "filterSections",
  }
}
</script>

<style scoped>

</style>